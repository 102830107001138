import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { SESSION_STORAGE } from './../constants';


interface PrivateRouteParams extends RouteProps {
    component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}

export function PrivateRoute({
    component: Component,
    ...rest
}: PrivateRouteParams) {
    let loggedin = false;
    const sessionSerializedData = localStorage.getItem(SESSION_STORAGE);
    if (sessionSerializedData) {
        try {
            const sessionData = JSON.parse(sessionSerializedData);
            const sessionClaims = JSON.parse(atob(sessionData.token.split('.')[1]));
            // is it still a valid token?
            if( Date.now() > sessionClaims.exp){
                loggedin = true;
            }
        } catch (err) {
            // err
        }
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                loggedin ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    )
}