
export const SESSION_STORAGE = 'glbSession';

export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_AUDIO = 'audio';

export const LOCATION_TYPE_POINT = 'Point';

export const SUPPORTED_LANGUAGES = [
    { code: 'es_es', name: 'Español España'},
    { code: 'en_us', name: 'English USA'},
];
